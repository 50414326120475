.transformSettingsModal {
  max-width: 600px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .settings-group {
    margin-bottom: 20px;

    p {
      margin-bottom: 10px;
    }

    input[type='number'],
    input[type='file'] {
      width: inherit;
      height: 30px;
      margin-top: 5px;
      display: block;
    }

    input[type='checkbox'] {
      width: 100%;
      height: 100%;
    }
  }

  .columnNameMap {
    margin-top: 20px;
    font-size: 14px;
  }

  .container {
    display: flex;
    justify-content: center;

    .transform-button {
      margin-top: 20px;
    }
  }
  .checkbox-container {
    width: 100%;
    height: 100%;
  }
}
