.repeat-mark-popup {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;


  h2 {
    margin: 0;
    padding-bottom: 10px;
  }

  label {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  input[type="radio"] {
    margin-right: 5px;
    vertical-align: middle;
  }

  input[type="text"] {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  PageRangeInput {
    margin-left: 10px;
  }

  .button-container {
    margin-top: 20px;
    text-align: left;
  }

  .container {
    display: flex;
    align-items: center;
  }

  button:disabled {
    opacity: 0.6;
    background-color: #ccc;
    cursor: not-allowed;
  }

  .rounded-button {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }

  .cancel-button {
    background-color: #217DA2;
    margin-right: 10px;
  }

  .ok-button {
    background-color: #217DA2;
  }
}
