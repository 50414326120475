.report-issue-form-label {
  font-size: 15px;
  letter-spacing: 0.1px;
  font-weight: 600;
  margin-bottom: 8px;
}

.report-issue-form-field {
  margin-bottom: 24px!important;
}

.attach-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.25px;
  color: #217DA2;
  cursor: pointer;
  margin-top: 16px;
}

.report-issue-filename {
  display: flex;
  align-items: center;
  font-size: 13px;
  letter-spacing: 0.4px;
}

.report-issue-file-icon {
  width: 16px!important;
  height: 16px!important;
  fill: #939598!important;
  margin-right: 8px;
}

.report-issue-file-delete-icon {
  width: 16px!important;
  height: 16px!important;
  fill: #939598!important;
  cursor: pointer;
}

.report-issue-protect-title {
  font-size: 21px;
  letter-spacing: 0.15px;
  font-weight: 600;
}

.report-issue-protect-optional {
  font-size: 13px;
  letter-spacing: 0.4px;
  font-weight: normal;
  margin-top: -4px;
  margin-bottom: 16px;
}
