.support-box {
  display: flex;
  flex-direction: column;
  width: 344px;
  height: 370px;
  margin-right: 32px;
}

.help-icon-wrapper {
  height: 172px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf5f8
}

.help-icon {
  width: 96px!important;
  height: 96px!important;
  fill: #5dabd3!important;
}

.support-title {
  font-size: 21px;
  font-weight: 600;
  margin-top: 8px;
  margin-left: 16px;
}

.contact-title {
  font-size: 25px;
  margin: 24px 32px;
}

.documentation-title {
  font-size: 25px;
  margin-top: 40px;
}

.documentation-box {
  width: 253px;
  height: 370px;
  margin-right: 32px;
}

.doc-wrapper {
  display: flex;
  width: 100%;
  height: 172px;
  justify-content: center;
  align-items: center;
  background-color: #F5F6F7;
}

.book-icon {
  width: 70px!important;
  height: 70px!important;
  fill: #9db15a!important;
}

.doc-title {
  font-size: 21px;
  letter-spacing: 0.15px;
  font-weight: 600;
  margin: 8px 16px;
}

.doc-text {
  font-size: 15px;
  letter-spacing: 0.24px;
  margin: 0 16px;
}

.guide-link {
  color: #217DA2;
  margin-left: 16px;
  margin-top: 14px;
  font-size: 15px;
  font-weight: 600;
}

.contact-text {
  margin-left: 32px;
  margin-right: 32px;
  font-size: 15px;
  letter-spacing: 0.24px;
}

.contact-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-left: 32px;
}

.contact-left-column {
  width: 82px;
  font-size: 15px;
  letter-spacing: 0.1px;
  font-weight: 600
}

.contact-details-text {
  font-size: 15px;
  letter-spacing: 0.24px;
}

.report-issue-title {
  font-size: 15px;
  letter-spacing: 0.1px;
  font-weight: 600;
  margin-top: 24px;
  margin-left: 32px;
}

.report-issue-chip {
  margin-right: 24px!important;
  padding: 0 5px!important;
  font-size: 14px!important;
  letter-spacing: 0.25px!important;
}

.report-issue-chip-selected {
  margin-right: 24px!important;
  padding: 0 5px!important;
  background-color: #217DA215!important;
  color: #374C72!important;
  font-size: 14px!important;
  letter-spacing: 0.25px!important;
  border: 1px solid #374C72!important;
}
