/* styles.scss */

.modal {
  min-width: 750px;
  width: 1000px;
  max-width: 1000px;
  max-height: 100%;

  .category-table-container {
    min-width: 720px;
    max-width: 100%;
    border: 5px solid #ccc;
    padding: 10px;
  }

  .table-container {
    margin-top: 10px;
    min-width: 700px;
    max-width: 100%;
    overflow-y: auto; /* Add a vertical scrollbar when content overflows */

    table {
      min-width: 700px;
      max-width: 100%;
      border-collapse: collapse;

      th, td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }

      th {
        background-color: var(--primary);;
        color: white;
      }

      tr:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .change-status-btn {
    margin-right: 10px;
    margin-left: 10px;
  }

  .missing {
    background-color: var(--strongRed);
    color: white;
    position: relative;
  }

  .tabs {
    display: flex;
    margin-top: 10px;
  }

  .tab-button {
    border: 1px solid #ccc;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
    margin-right: -1px;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #e0e0e0;
    }

    &.active {
      border: 3px solid var(--primary);
      border-radius: 8px 8px 0 0;
      z-index: 1;
    }
  }

}

h2 {
  text-align: center;
}

/* loading-spinner */

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  border-radius: 50%;
  margin: 0 5px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
