.redaction-item {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 3px #ADB5BD;
  margin: 8px 12px 0px 12px;
  width: 100%;

  .Icon {
    svg {
      transform: scale(1.2);
      padding-top: 2px;
    }
  }

  .search-value {
    word-break: break-all;
    color: var(--multipanelSecondaryButtonText);
    font-weight: bold;
  }

  &.active {
    background-color: #DDE6EE !important;
  }
}

.redaction-item-selected {
  background-color: #DDE6EE !important;
}

.redaction-item-info {
  flex: 1;
  padding-left: 18px;
  padding-right: 20px;
}

.redaction-item-preview {
  font-size: 13px;
  color: #485056;
}

.redaction-item-date-author {
  font-size: 10px;
  color: #868E96;
}