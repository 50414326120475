.change-password-wrapper {
  width: 330px;
  height: 644px;
  padding: 0 48px;
}

h5 {
  font-size: 25px;
  color: #223250;
  font-weight: normal;
  margin-top: 32px;
  margin-bottom: 16px;
}

.password-text-field {
  margin-bottom: 16px!important;
}

.legend-row {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;
  margin-bottom: 4px;
}

.legend-bullet {
  width: 8px!important;
  height: 8px!important;
  margin-right: 12px;
}

.buttons-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
}
