h5 {
  font-size: 25px;
  color: #223250;
  font-weight: normal;
}

.change-name-wrapper {
  width: 328px;
  height: 308px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 46px;
}

.change-name-modal-title {
  font-size: 25px;
  letter-spacing: 0;
  margin-top: 48px;
  margin-bottom: 32px;
}

.full-name-label {
  align-self: start;
  margin-bottom: 8px;
}

.change-name-buttons-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
}
