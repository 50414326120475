.layout {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
    height: 100%;
    width: 100%;
}

.content {
    height: calc(100vh - 64px - 32px);
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.outlet {
    display: flex;
    flex-direction: column;
    flex-grow: 4;
    width: 80%;
    overflow-y: auto;
}

.app-layout-change-pass-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 101px;
    margin-left: 32px;
    margin-right: 32px;
    background-color: white;
}

.app-layout-pass-banner-text-wrapper {
    margin: 16px 24px;
    font-size: 15px;
}

.app-layout-pass-banner-text-alert {
    color: var(--strongRed);
    font-weight: 600;
}

.app-layout-pass-banner-buttons-wrapper {
    display: flex;
    margin-right: 15px;
}

.collapse-button {
    height: 24px;
    position: relative;
    top: 120px;
    left: -12px;
    cursor: pointer;
    box-shadow: 0px 5px 10px -5px black;
    border-radius: 50%;
    border: white;
    opacity: 0.6;
}
