
.Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  border-color: black;
  border-width: 10px;
}

ul.nav {
  display: flex;
  padding-left: 0px;
}
ul.nav li {
  padding: 1rem;
  list-style: none;
  text-align: left;
  cursor: pointer;
  transition: all 0.7s;
  font-weight: 600;
}

ul.nav li.active {
  color: var(--secondary);
  border-bottom: 2px solid var(--secondary)
}

.DetailsTab p,
.RegexTab p {
  font-size: 2rem;
  text-align: center;
  width: 100%;
}
