:root {
    --mini-button-color: rgb(241, 249, 255);
}

:root {
    --main-accent-color: rgb(47, 154, 248);
}

.wizard-container {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.wiz-button {
    width: 103px;
    align-self: center;
}

.selected {
    color: var(--main-accent-color);
}
h1 {
    font-size: 2em;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
}
.wizard-out {
    display: flex;
    justify-content: space-between;
    margin: 15px;
    text-align: center;
}

.wizard-title {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    margin-left: 15px;
    margin-right: 15px;
}
.wizard-content {
    display: flex;
    flex-direction: column;
    flex-grow: 8;
    padding-left: 25px;
    padding-right: 25px;
}
.wizard-content-label {
    font-size: 15px;
    margin-bottom: 10px;
    letter-spacing: 0.49px;
}

.wizard-color-circle.selected::before {
    content: '';
    margin: -6px;
    border-radius: 50%;
    display: block;
    border-style: solid;
    border-width: 2px;
    width: 43px;
    height: 43px;
    color: #4195fc;
}
.wizard-color-circle {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    margin: 5px;
    display: block;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
}
.wizard-color-profile{
    height: 64px;
    border-radius: 32px;
    cursor: pointer;
    width:64px;
    display: flex;
    flex-direction: column;
    font-size: 36px;
    font-weight: 600;
    justify-content:center;
    text-align:center;
    color: white; 
    align-self: center;
    margin-right: 20px;
    margin-left: 10px;  
}

.wizard-color-align{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}
.wizard-color-wrap{
    display: flex;
     flex-wrap: wrap;
    flex-direction: row;
    color: white;
    margin-bottom: -10px;
}
