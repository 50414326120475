.sticky-column {
  position: sticky;
  left: 0;
  background-color: #ffffff;
  z-index: 30;
}

.sticky-column-2 {
  position: sticky;
  left: 51px;
  background-color: #ffffff;
  z-index: 30;
  @media  screen and (max-width: 1200px) {
    min-width: 200px !important;
  }
}

.last-row {
  border-bottom: 1px solid var(--lightGrayishBlue2)
}

.task-files-button  {
  font-size: 13px !important;
}

.cell-left-border  {
  border-left: 1px solid var(--lightGrayishBlue2)
}

.projects-table-header  {
  text-align: center;
  font: 15px bold, normal !important;
  font-weight: bold !important;
  letter-spacing: 0.24px;
}

.table-left-padding > *  {
  padding-left: 10px;
}

.cell-right-border  {
  border-right: 1px solid var(--lightGrayishBlue2)
}

.cell-bottom-border  {
  border-bottom: 1px solid var(--lightGrayishBlue2)
}

.page-navigate-button {
  float: right;
  display: inline-block;
  font-size: 28px !important;
  margin-top: 10px !important;
  height: 25px;
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
}

.selected-row-first {
  border-top: 1px solid #374C72!important;
  border-left: 1px solid #374C72!important;
  border-bottom: 1px solid #374C72!important;
}

.selected-row-middle {
  border-top: 1px solid #374C72!important;
  border-bottom: 1px solid #374C72!important;
}

.selected-row-last {
  border-top: 1px solid #374C72!important;
  border-right: 1px solid #374C72!important;
  border-bottom: 1px solid #374C72!important;
}

#editable-div:focus {
  outline: none;
}

.cell-right-border  {
  border-right: 1px solid var(--lightGrayishBlue2)
}
.cell-bottom-border  {
  border-bottom: 1px solid var(--lightGrayishBlue2)
}
.page-navigate-button {
  float: right;
  //display: inline-block;
  font-size: 28px !important;
  margin-top: 10px !important;
  height: 25px;
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
}

