.remove-modal-container {
    min-width: 420px;
    max-width: 460px;
    background-color: white;
}

.content-remove-modal {
    margin: 16px 32px 32px 32px;
    display: flex;
    flex-direction: column;
}

.remove-entity-close-button {
    align-self: flex-end;
    cursor: pointer;
}

.remove-entity-title {
    color: var(--strongRed);
    font-size: 21px;
    font-weight: 600;
}

.remove-entity-disclaimer {
    font-size: 15px;
}

.remove-entity-check {
    font-size: 15px;
    color: var(--strongRed);
    font-weight: 600;
}

.remove-entity-button-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}