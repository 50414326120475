.project-create-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 46px
}

.project-review-item {
  display: flex ;
  justify-content: space-between;
  border: 1px solid #E6E7E8;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  align-items: center;
}

.project-review-item:hover {
  background-color: var(--lightGrayishBlue3);
}

.project-review-value {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.49px;
}

.wizard-color-circle.selected::before {

}
