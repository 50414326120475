@import '../styles';
@import '../panel-constants';

.multipanel .redaction-group-container {
  height: 160px !important
}

.multipanel {
  .redaction-group-container {
    height: calc(100vh - 500px) !important;
    overflow: auto;
  }

  .checkButton {
    @include button-reset;
    flex: 1 1 auto;
    margin-right: 16px;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}


.redaction-panel-controls {
  flex: 0 0 57px;
  margin-left: -16px;
  border-top: 1px solid #CFD4DA;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: white;
  width: inherit;
  justify-content: flex-end;
  padding-right: 16px;

  .redact-all-marked {
    @include button-reset;
    background-color: var(--multipanelPrimaryButton);
    color: var(--multipanelPrimaryButtonText);
    border-radius: 4px;
    height: 32px;
    width: 90px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

  }

  .transform-button {
    @include button-reset;
    background-color: var(--multipanelPrimaryButton);
    color: var(--multipanelPrimaryButtonText);
    border-radius: 4px;
    margin-right: 5px;
    height: 32px;
    width: 100%;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .transform-menu {
    border-radius: 10px;
  }

  .clear-all-marked {
    @include button-reset;
    color: var(--multipanelSecondaryButtonText);
    background-color: transparent;
    border: none;
    height: 32px;
    width: 70px;
    padding-right: 5px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  @include mobile {
    left: 0px;
  }
}

.submenu-icon {
  height: 15px !important;
  margin-left: auto;
}

.redaction-panel-controls-top {
  flex: 0 0 57px;
  margin-left: -16px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: inherit;
  width: inherit;
  justify-content: flex-end;
  padding-right: 16px;

  .redact-all-marked {
    @include button-reset;
    background-color: var(--multipanelPrimaryButton);
    color: var(--multipanelPrimaryButtonText);
    border-radius: 4px;
    height: 32px;
    width: 90px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

  }

  .clear-all-marked {
    @include button-reset;
    color: var(--multipanelSecondaryButtonText);
    background-color: transparent;
    border: none;
    height: 32px;
    width: 70px;
    padding-right: 5px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  @include mobile {
    left: 0px;
  }
}

.redaction-panel-controls-bottom {
  display: flex;
  flex-direction: row;
  flex: 0 1 52px;
  justify-content: flex-end;
  padding: 10px;
  background-color: white;
  border: 1px solid var(--multipanelBorder);
  margin-bottom: 0px;
  min-height: 100px;

  button {
    @include button-reset;
    height: 28px;
    padding: 6px 16px;
    cursor: pointer;

    @include mobile {
      height: 32px;
    }
  }

  .cancel {
    flex: 2 1 auto;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;
    margin-right: 20px;
  }

  .redact-all-marked {
    @include button-reset;
    background-color: var(--multipanelPrimaryButton);
    color: var(--multipanelPrimaryButtonText);
    border-radius: 4px;
    height: 32px;
    width: 90px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

  }

  .clear-all-marked {
    @include button-reset;
    color: var(--multipanelSecondaryButtonText);
    background-color: transparent;
    border: none;
    height: 32px;
    width: 70px;
    padding-right: 5px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.RedactionPanel {
  padding: 0px 16px 0px 0px;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100vw;
    min-width: 100vw;
    padding-top: 0px;

    .close-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 64px;

      width: 100%;
      padding-right: 12px;

      .close-icon-container {
        cursor: pointer;

        .close-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .marked-redaction-counter {
    flex: 3 1 auto;

    span {
      font-weight: bold;
    }
  }

  .no-marked-redactions {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;

    .msg {
      @include tablet-and-desktop {
        line-height: 15px;
        width: 146px;
      }

      text-align: center;
    }

    .empty-icon {
      width: 65px;
      height: 83px;

      svg {
        width: 65px;
        height: 83px;
      }

      * {
        fill: #CFD4DA;
        color: #CFD4DA;
      }
    }
  }

  .redaction-group-container {
    overflow-y: scroll;
    flex: 1 1 auto;
    height: 250px
  }

  .checkButton {
    @include button-reset;
    flex: 1 1 auto;
    margin-right: auto;
    margin-left: 10px;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

}
