.container {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

.contentBox {
    display: flex;
    flex-direction: column;
    margin: 32px 49px 40px 50px;
}

.title {
    font-size: 25px;
}

.detail {
    word-break: break-word;
    font-size: 15px;
}

.password {
    font-size: 13px;
}

.error-password {
    font-size: 13px;
    letter-spacing: 0.4px;
}

.requirement {
    font-size: 13px;
}

li {
    list-style: none;
}

/*.li::before {
    content: "\2022";
    font-size: 30px;
    position: relative;
    top: 5px;
    margin-right: 8px;
    font-family: initial;
}*/

li.main-li::before {
    color: var(--secondary)
}

.gray-li {
    color: var(--darkGrayishBlue);
}

.error-li {
    color: var(--strongRed);
}

.policy {
    font-size: 13px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.reference {
    text-decoration: none;
    color: var(--secondary);
}
