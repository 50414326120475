.header-accounts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: 36px;
}

.button {
    height: 39px;
    align-self: center;
    width: 171px;
}

.account-wrapper {
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;
}

.account {
    margin-right: 30px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 150px;
}

.account-circle {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    border-radius: 60px;
}

.account-letter {
    font-size: 67px;
    color: white;
    align-self: center;
    font-weight: 600;
}

.account-name {
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
}
