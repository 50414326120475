.roles-overview-body {
  font-size: 17px;
}

.user-roles-title {
  font-size: 25px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #217DA2;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
  margin-bottom: 4px;
}

.user-row {
  display: flex;
  align-items: center;
  width: 974px;
  min-height: 55px;
  border: 1px solid #E6E7E8;
  font-size: 15px;
}

.role-panel {
  display: flex;
  flex-direction: column;
  width: 251px;
  height: 90px;
  border-radius: 4px;
  padding: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
  cursor: pointer
}

.role-panel-assigned {
  font-size: 17px;
  letter-spacing: 0.49px;
}

.permissions-column {
  display: flex;
  flex-direction: column;
  width: 219px;
}
