.box-container {
    display: flex;
    justify-content: center;
    background-color: var(--lightGrayishBlue);
    width: 100%;
    height: fit-content;
}

.content-box {
    display: flex;
    flex-direction: column;
    margin: 32px 48px 48px 48px;
}

.header {
    font-size: 34px;
    text-align: center;
}

.details {
    font-size: 17px;
    text-align: center;
    margin-top: 10px;
}

.forgotPass {
    color: var(--secondary);
    text-align: end;
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
}

.forgot-pass-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -5px;
}

.hyperlink-text {
    color: var(--secondary);
}
