// Dimensions
$top-bar-height: 36px;
$button-width: 46px;
$button-height: 46px;
$icon-width: 24px;
$icon-height: 24px;
$panel-width: 293px;
$right-panel-width: 293px;
$left-panel-width: 219px;
$left-panel-header-margin-left: 16px;
$left-header-width: 41px;
$right-header-width: 41px;

// Export so they can be used in JS file and we have a single source of truth
:export {
  LEFT_HEADER_WIDTH: $left-header-width;
  RIGHT_HEADER_WIDTH: $right-header-width;
}

// Font
$font-family: sans-serif;

// Mobile dimensions
$top-bar-height-m: 42px;
$button-width-m: 41px;
$button-height-m: 41px;
$search-overlay-height-m: 61px;

// TODO: Remove
// Old Colors
$white: #fff;
$pdftron-blue: #00a5e4; // TODO: confirm that this is what we want

// TODO: Remove
// Light theme
$document-bg-color: $white;

// Shadows
$md-shadow1: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
0 1px 3px 0 rgba(0, 0, 0, 0.12);

// Screens
$tablet-width: 900px;
$mobile-width: 640px;
$small-mobile-width: 430px;

$signature-modal-shared-color: #e1e1e3;

// Dark defaults for new ui:
$gray-3: #343A40;
$light-gray: #e7ebee;

%open {
  &.open {
    visibility: visible;
  }
}

%closed {
  &.closed {
    visibility: hidden;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-width + 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
    @at-root .App:not(.is-in-desktop-only-mode) & {
      @content;
    }
  }
}

@mixin tablet-and-desktop {
  @media (min-width: #{$mobile-width + 1px}) {
    @at-root .App:not(.is-in-desktop-only-mode) & {
      @content;
    }
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @at-root .App:not(.is-in-desktop-only-mode) & {
      @content;
    }
  }
}

@mixin small-mobile {
  @media (max-width: #{$small-mobile-width}) {
    @at-root .App:not(.is-in-desktop-only-mode) & {
      @content;
    }
  }
}

@mixin tablet-and-mobile {
  @media (max-width: #{$tablet-width}) {
    @at-root .App:not(.is-in-desktop-only-mode) & {
      @content;
    }
  }
}

@mixin ie11 {

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    @content;
  }
}

@mixin small-browser-height {
  @media (max-height: 500px) {
    @content;
  }
}

@mixin tiny-browser-height {
  @media (max-height: 320px) {
    @content;
  }
}

.hide-in-desktop {
  @include desktop {
    display: none;
  }
}

.hide-in-tablet {
  @include tablet {
    display: none;
  }
}

.hide-in-mobile {
  @media (max-width: #{$mobile-width}) and (min-width: #{$small-mobile-width + 1}) {
    display: none;
  }
}

.hide-in-small-mobile {
  @media (max-width: #{$small-mobile-width}) {
    display: none;
  }
}

%cell {
  width: 30px;
  height: 26px;
  display: flex;
  margin: 1px;

  @include mobile {
    height: 30px;
    width: 13.5%;
  }
}

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
%word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// Fixes default button styling, and prevents outline when not tabbing.
@mixin button-reset {
  padding: 0;
  border: none;
  background-color: transparent;

  @at-root html:not([data-tabbing='true']) #{&} {
    outline: none;

    // No need to use this if you aren't doing custom focus style.
    // &::-moz-focus-inner {
    //   border: 0;
    // }
  }
}

// This is hack to have an explicit height for a panel. This is required because to use height auto
// we have to position a parent container. But doing so breaks mouse/touch coordinates. So we use calc
// to get an explicit height.
// https://stackoverflow.com/questions/12070759/make-absolute-positioned-div-expand-parent-div-height
@mixin panel-height-fix {

  height: calc(100% - 40px);

  &.tools-header-open {
    height: calc(100% - 80px);
  }

  &.multi-tab-active {
    height: calc(100% - 120px);

    @include tablet {
      height: calc(100% - 140px); // minus the height of both headers and of the tabs;
    }
  }

  @include desktop {
    height: calc(100% - 40px);

    &.tools-header-open {
      height: calc(100% - 74px); // minus the height of both headers;
    }

    &.multi-tab-active {
      height: calc(100% - 120px); // minus the height of both headers and of the tabs;
    }
  }

  @include tablet-and-desktop {
    height: calc(100% - 37px); // minus the height of the top header;

    &.tools-header-open {
      height: calc(100% - 76px); // minus the height of both headers;
    }

    &.multi-tab-active {
      height: calc(100% - 170px);
      overflow: auto;
    }

    &.tools-header-and-header-hidden {
      height: 100%;

      &.multi-tab-active {
        height: calc(100% - 140px); // minus the height of both headers and of the tabs;
      }
    }
  }

  @include tablet {
    height: calc(100% - 53px); // minus the height of the top header;
  }

  &.tools-header-open {
    @include tablet {
      height: calc(100% - 104px); // minus the height of both headers;
    }
  }
}

.always-hide {
  display: none;
}