@import '../styles';

.redaction-search-counter-controls {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  font-size: var(--font-size-default);
  padding: 10px;
  border: 1px solid var(--multipanelBorder);
  background-color: white;
  border-radius: 4px 4px 0px 0px;
  max-height: 100px;

  .redaction-search-results-counter {
    flex: 3 1 auto;

    span {
      font-weight: bold;
    }
  }

  .spinner {
    margin: auto;
    flex: 3 1 '25px';
  }

  button {
    @include button-reset;
    flex: 1 1 auto;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

}

.redaction-search-results-container {
  flex: 1 1 auto;
  background-color: #F1F3F5;
  color: #ADB5BD;
  font-size: 13px;
  border-left: 1px solid var(--multipanelBorder);
  border-right: 1px solid var(--multipanelBorder);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 500px) !important;
  margin-bottom: 0px;
  &.emptyList {
    justify-content: center;
    align-items: center;
  }
}

.MultiPanel .redaction-search-results-container {
  height: 200px;
}

.redaction-search-no-results {
  justify-content: center;
  align-items: center;
}

.redaction-search-panel-controls {
  display: flex;
  flex-direction: row;
  flex: 0 1 52px;
  padding: 10px;
  background-color: white;
  border: 1px solid var(--multipanelBorder);
  margin-bottom: 0px;
  min-height: 100px;

  button {
    @include button-reset;
    height: 28px;
    padding: 6px 16px;
    cursor: pointer;

    @include mobile {
      height: 32px;
    }
  }

  .cancel {
    flex: 2 1 auto;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;
    margin-right: 20px;
  }

  .redact-all-selected {
    flex: 1 1 auto;
    border: 1px solid var(--multipanelSecondaryButtonText);
    border-radius: 4px;
    color: var(--multipanelSecondaryButtonText);
    margin-right: 8px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .delete-all-selected {
    flex: 1 1 auto;
    border: 1px solid var(--multipanelSecondaryButtonText);
    border-radius: 4px;
    color: var(--multipanelSecondaryButtonText);
    margin-right: 8px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .mark-all-selected {
    flex: 1 1 auto;
    background-color: var(--multipanelPrimaryButton);
    border: 1px solid var(--multipanelPrimaryButton);
    border-radius: 4px;
    color: #F8F9FA;
    border-radius: 4px;
    // width: 90px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.redaction-search-panel-controls-top {
  display: flex;
  flex-direction: row;
  flex: 0 1 52px;
  padding: 10px;
  background-color: inherit;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 20px;

  button {
    @include button-reset;
    height: 28px;
    padding: 6px 16px;
    cursor: pointer;

    @include mobile {
      height: 32px;
    }
  }

  .cancel {
    flex: 2 1 auto;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;
    margin-right: 20px;
  }

  .redact-all-selected {
    flex: 1 1 auto;
    border: 1px solid var(--multipanelSecondaryButtonText);
    border-radius: 4px;
    color: var(--multipanelSecondaryButtonText);
    margin-right: 8px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .delete-all-selected {
    flex: 1 1 auto;
    border: 1px solid var(--multipanelSecondaryButtonText);
    border-radius: 4px;
    color: var(--multipanelSecondaryButtonText);
    background-color: white;
    margin-right: 8px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .mark-all-selected {
    flex: 1 1 auto;
    background-color: var(--multipanelPrimaryButton);
    border: 1px solid var(--multipanelPrimaryButton);
    border-radius: 4px;
    color: #F8F9FA;
    border-radius: 4px;
    // width: 90px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
