.box-content {
    display: flex;
    flex-direction: column;
    margin: 16px 48px 48px 48px;
}

.horizontal-line {
    border: 1px solid var(--lightGrayishBlue);
    margin-top: 15px;
    margin-bottom: 15px;
}