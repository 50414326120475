.my-tasks-wrapper {
    display: flex;
    flex-direction: column;
}

.my-tasks-inner-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 7px;
}

.my-tasks-title {
    font-size: 32px;
    margin: 12px 15px 15px 0px;
    flex-grow: 1;
}

.my-tasks-group-by-modal {
    width: 138px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
}

.my-tasks-group-by-modal-title {
    font-size: 11px;
    letter-spacing: 1.5px;
    margin-left: 15px;
    color: gray;
}

.my-tasks-gorup-by-modal-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 5px 0 5px 15px;
    cursor: pointer;
}

.my-tasks-group-by-modal-check {
    margin-right: 10px;
     color: var(--secondary);
}

.my-tasks-item-title {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
}

.my-tasks-item-count {
    font-size: 13px;
    align-self: center;
    margin-left: 3px;
}

.my-tasks-task-wrapper {
    border: 1px solid var(--lightGrayishBlue2);
}

.my-tasks-task-inner-box {
    margin: 8px 12px 13px 21px;
}

.my-tasks-task-name {
    font-size: 11px;
    text-transform: uppercase;
}

.my-tasks-task-name-arrow {
    font-size: 13px;
    position: relative;
    bottom: 1px;
}

.my-tasks-task-details-wrapper {
    display: flex;
}

.my-tasks-task-filename {
    font-size: 15px;
    font-weight: 600;
}

.my-tasks-task-subtask-counter {
    font-size: 13px;
    margin-left: 5px;
}

.my-tasks-task-date {
    font-size: 13px;
    align-self: center;
    min-width: 50px;
    text-align: center;
}

.my-tasks-overdue-date {
    color: var(--strongRed);
}

.my-tasks-sub-task-wrapper {
    display: flex;
    align-items: center;
}

.my-tasks-sub-task-arrow-right {
    width: 24px;
    height: 24px;
    margin-left: -1px;
}

.my-tasks-sub-task-horizontal-line {
    border: 0.5px solid var(--lightGrayishBlue2);
    margin-left: 30px;
}
