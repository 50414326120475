@mixin snackbar {
  color: white;
  font-size: 15px;
  letter-spacing: 0.24px;
  padding: 14px 22px;
  width: 344px;
  border-radius: 4px;
}

.snackbar-message-info {
  @include snackbar;
  background-color: #223250;
}

.snackbar-message-error {
  @include snackbar;
  background-color: darkred;
}

.MuiSnackbar-anchorOriginBottomLeft {
  bottom: 88px!important;
  left: 32px!important;
}
