.multipanel {
  .redaction-search-result {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 3px #ADB5BD;
    margin: 8px 0px;

    .Icon {
      svg {
        transform: scale(1.2);
        padding-top: 2px;
      }
    }

    .search-value {
      word-break: break-all;
      color: var(--multipanelSecondaryButtonText);
      font-weight: bold;
    }

    &.active {
      background-color: #DDE6EE !important;
    }
  }

  // box shadow for active ??
  // &.active {
  // box-shadow: 0px 0px 4px #868E96, 0px 4px 16px rgba(134, 142, 150, 0.24);
  // }

  .redaction-search-result-info {
    font-size: 13px;
    color: #485056;
  }
}
