.container-fluid {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.table-and-reset-button-container {
    display: flex;
    align-items: center;
    gap: 20px; /* Adjust the gap between the table and the button as needed */
}


.bg-light {
    background-color: #ffffff!important;
}

.mt-3 {
    margin-top: 1rem!important;
}

.pr-4 {
    padding-right: 1rem!important;
}

.position-relative {
    position: relative!important;
}

.col-sm-1 {
    flex: 0 0 8.3333333333%!important;
    max-width: 8.3333333333%!important;
}

.p-4 {
    padding: 1rem!important;
}

.d-none {
    display: none!important;
}

.text-center {
    text-align: center!important;
}

.display-3 {
    font-size: 4.5rem!important;
    font-weight: 300!important;
    line-height: 1.2!important;
}

.text-secondary {
    color: #6c757d!important;
}

.col-12 {
    flex: 0 0 100%!important;
    max-width: 100%!important;
}

.col-md-11 {
    flex: 0 0 91.6666666667%!important;
    max-width: 91.6666666667%!important;
}

.py-4 {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
}

.pl-4 {
    padding-left: 1rem!important;
}

.d-flex {
    display: flex!important;
}

.justify-content-between {
    justify-content: space-between!important;
}

h5 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}


.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert p {
    margin-bottom: 0;
}

.alert-dismissible {
    padding-right: 3rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-dismissible .close:hover,
.alert-dismissible .close:focus {
    color: inherit;
    text-decoration: none;
}

.alert .alert-link {
    font-weight: 700;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.custom-checkbox {
    position: relative;
    display: inline-block;
    padding-left: 2.5rem;
    margin-bottom: 0;
}

.custom-checkbox .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-checkbox .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-checkbox .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox .custom-control-label {
    margin-bottom: 0;
}

.form-text.text-muted {
    color: #6c757d!important;
}

.form-text.text-muted a {
    color: #6c757d!important;
    text-decoration: none;
}


.position-absolute {
    position: absolute!important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.form-group {
    margin-bottom: 1rem;
}

