.folder-and-file-select-drag-drop {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  text-align: center;
  margin: 16px;
  flex: 1;
}

.folder-and-file-select-drag-drop-label {
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-weight: 600;
  color: #2484ff;
  border-radius: 8px;
}
