.multipanel {
  @import './styles';
  @import './panel-constants';

  .container {
    align-items: center;
  }

  .option-checkbox {
    width: 24px;
    height: 24px;
  }

  .search-options-container {
    display: flex;
    align-items: center;
  }

  .flx-Panel {
    z-index: 65;
    background-color: var(--multipanelBackground);
    display: flex;
    position: fixed;
    overflow: hidden;
    transition: transform .3s ease-in-out;
    max-width: calc(100% - 16px);

    .flx-Panel-container {
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    &.right {
      right: 0;
      border-left: 1px solid var(--multipanelSideBorder);
    }

    &.left {
      left: 0;
      border-right: 1px solid var(--multipanelSideBorder);
    }

    &.right.closed {
      transform: translateX(100%);
      left: auto;
    }

    &.left.closed {
      transform: translateX(-100%);
    }
  }

  .checkButton {
    flex: 1 1 auto;
    margin-right: 16px;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .flx-Panel .ThumbnailsPanel,
  .flx-Panel .documentControlsContainer {
    margin: 0 16px;
  }

  .margin-button {
    background-color: var(--multipanelPrimaryButton);
    border: 1px solid var(--multipanelPrimaryButton);
    border-radius: 4px;
    color: #F8F9FA;
    // width: 90px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
