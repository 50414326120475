body {
  font: 15px Titillium Web, normal;
  letter-spacing: 0.24px;
}

.App {
  text-align: center;
  font: 15px Titillium Web, normal;
  letter-spacing: 0.24px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.progress-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  bottom: 0;
  right: 0;
  z-index: 99999;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #474bff 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, #474bff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

.spinner-position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
}

.change-status-btn {
  display: block;
  padding: 0.2rem 0.6rem;
  margin: 0.4rem 0;
  border-radius: 4px;
  border: none!important;
  &.success {
    color: rgb(255, 255, 255);
    background: rgb(33, 125, 162);
  }
  .cancel {

  }
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

.App-link {
  color: #61dafb;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #EDF5F8 !important;
}

.MuiPickersDay-root {
  width: 32px !important;
  height: 32px !important;
  margin: 0 !important;
}

.MuiCalendarPicker-root {
  width: 256px !important;
  max-height: 310px !important;
}

.PrivatePickersSlideTransition-root {
  min-height: 216px !important;
}

.PrivatePickersFadeTransitionGroup-root {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.MuiPickersArrowSwitcher-spacer {
  width: 6px !important;
}

.MuiButton-root {
  font-size: 15px !important;
  font-weight: 600 !important;
  letter-spacing: 1.25px !important;
}

.MuiOutlinedInput-root .Mui-disabled {
  -webkit-text-fill-color: #374C72 !important;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: rgba(0, 0, 0, 0.38);
  background-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #939598;
  border-radius: 4px;
}

::-webkit-scrollbar:hover {
  cursor: pointer !important;
}

/*
  pagination
*/
.pagination ul{
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    cursor: pointer;
    a {
      padding: 8px;
      position: relative;
      display: block;
      color: #217DA2;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #217DA2;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
  }
  li.selected{
    a{
      z-index: 3;
      color: #fff;
      background-color: #217DA2;
      border-color: #217DA2;
    }
  }
  li.previous, li.next{
    a{
     padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/*
    WEB VIEWEER

*/
.webviewer {
  width: 100% !important;
}

.multipanel{
  position: fixed;
  width: 370px !important;
  right: 0;
  z-index: 9999;
  height: calc(-266px + 100vh) !important;
  background: #e7ebee;
  transition: 300ms;
  &.close{
    right: -370px;
    opacity: 0;
  }
}
.document-content-container.add-width {
  width: calc(100% - 370px) !important;
}
//document.querySelector(".multipanel")!.classList.add("close")
//TabsHeader Header MainHeader
//HeaderToolsContainer