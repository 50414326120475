
.input-container {
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border: none;
  height: 28px;
  align-items: center;
  justify-content: flex-end;
  color: var(--text-color);
  padding: 6px 2px 6px 6px;
  background: var(--component-background);

  input {
    width: 100%;
    padding-right: 26px;
    height: 20px;
    background: white;
    border: 1px solid lightgrey;
  }

  input::placeholder {
    color: lightgrey;
  }

  .clearPageRange-button {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;

    svg {
      color: var(--gray-7);
    }
    &:hover {
      background: var(--blue-1);
    }
  }
}
