.table-body-single-project-template td:not(:first-child) {
    border-top: 1px solid var(--lightGrayishBlue2);
}

.table-body-single-project-template td:nth-child(2) {
    border-left: 1px solid var(--lightGrayishBlue2);
}

.table-body-single-project-template td:last-child {
    border-right: 1px solid var(--lightGrayishBlue2);
}

.single-project-template-wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.single-project-template-box {
    margin: 32px 32px 32px 10px;
    background-color: white;
}

.single-project-template-box-no-scroll {
    background-color: white;
}

.single-project-template-header {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
}

.single-project-template-back {
    margin-top: 10px;
}

.single-project-template-name {
    font-size: 21px;
    font-weight: 600;
    position: relative;
    bottom: 5px;
    margin-left: 17px;
}

.single-project-template-archived {
    border: 1px solid var(--lightGrayishBlue2);
    font-size: 14px;
    padding: 5px;
    font-weight: 600;
    position: relative;
    bottom: 5px;
    margin-left: 17px;
}

.single-project-temaplate-back-button {
    cursor: pointer;
}

.single-project-template-horizontal-line {
    border-bottom: 1px solid var(--lightGrayishBlue2);
    margin-top: 2px;
    margin-bottom: 15px;
}

.single-project-template-list-separator {
    border: 0.2px solid var(--lightGrayishBlue2);
    margin-top: 25px;
}

.single-template-list-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.single-template-list-header-short-view {
    display: flex;
    justify-content: space-between;
}

.single-template-list-name {
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.15px;
}

.single-template-list-table {
    border-collapse: separate;
    border-spacing: 0;
    color: var(--darkGrayishBlue);
    text-align: center;
    border: none;
}

.no-top-border {
    border-top: none;
}

.single-template-list-table-head {
    font-size: 11px;
    height: 39px;
}

.single-template-task-row {
    min-height: 48px;
}

.single-template-task-name {
    font-size: 15px;
    text-align: left;
}

.single-template-task-icon {
    position: relative;
    top: 5px;
}

.single-template-task-status {
    font-size: 14px;
    background-color: var(--lightGrayishBlue2);
    padding: 5px;
}

.hover-state-template-icon {
    color: white !important
}

.hover-state-template-icon:hover {
    background-color: #E6E7E81A !important;
    color: #939598 !important
}

.input-duplicate-tasks-menu:after {
    margin-top: 10px;
}

.focused-state-template-icon {
    color: black !important;
    background-color: #e6e7e8 !important;
}
