@mixin project-filter-item {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    display: flex;
    align-items: center;
    height: 36px;
    margin-left: 24px;
    float: left;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.project-filter-item {
    @include project-filter-item;
}

.project-filter-item-selected {
    @include project-filter-item;
    background-color: var(--lightGrayishBlue2);
    border-radius: 16px;
    font-weight: 600;
    color: var(--secondary);
}

.project-filter-item-toggle {
    @include project-filter-item;
    background-color: var(--lightGrayishBlue3);
    border-radius: 16px;
    font-weight: 600;
    color: var(--secondary);
}

.project-filter-item:hover {
    background-color: var(--lightGrayishBlue3);
    border-color: var(--secondary);
    transition: background-color .6s, border-color .6s ease;
    border-radius: 16px;
}

.project-filter-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--lightGrayishBlue);
    height: 56px;
}

.project-filter-bar-search {
    display: flex;
    align-items: center;
}

.project-filter-item-check {
    color: var(--secondary);
}

.project-filters-assignees-hr {
    border: 0.5px solid var(--lightGrayishBlue2);
    margin-bottom: 8px;
    margin-top: 5px;
}

.project-filters-modal-title {
    font-size: 11px;
    margin-left: 16px;
    letter-spacing: 1.5px;
}

.project-filters-filter-wrapper {
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    letter-spacing: 0.24px;
}

.project-filters-filter-date-wrapper {
    width: 156px;
    border: 1px solid #0000001F;
    height: 32px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 4px;
}

@mixin project-filters-filter-date {
    font-size: 15px;
    letter-spacing: 0.24px;
}

.project-filters-filter-date {
    @include project-filters-filter-date;
}

.project-filters-filter-date-disabled {
    @include project-filters-filter-date;
    color: var(--darkGrayishBlue);
}

.project-filters-tooltip-wrapper {
    border-radius: 4px;
    background-color: #0A183299;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-filters-tooltip-space {
    height: 4px;
    background-color: white;
}
