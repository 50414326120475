.multipanel {

  .transform_table_container {
    overflow-y: scroll;
    flex: 1 1 auto;
    height: calc(100vh - 490px);
  }

  .transform_table_cell {
    border: 1px solid black;
    padding: 3px;
  }

  .transform_column_header {
    background-color: #374c72;
    color: white;
    padding: 3px;
  }
}

.transform-item-selected {
  color: white !important;
  background-color: blue !important;
  border: 1px solid black;
  padding: 3px;
}

.transform-icon {
  text-align: center !important;
  width: 100% !important;
  font-size: 100px !important;
  margin-top: 20px;
}