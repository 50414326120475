.body-container {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - var(--headerHeight) - var(--footerHeight));
}

.DocViewer {
  width: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 32px 32px 10px 32px;
  background-color: white;
  padding: 12px 24px 32px 32px;
  font-size: 17px;
  color: #223250;
}

.page-header {
  display: flex;
  align-items: center;
  color: #374C72;
  font-size: 36px;
  margin-bottom: 24px;
}

.page-header-small {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.arrow-back {
  color: #939598;
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 24px;
}
.sanitize-first-modal{
  background-color: #005da9;
  outline: none;
  border: none;
  color: #ffffff;
  padding: 5px 10px;
}
.corrupt-pages-cancel-button{
  outline: none;
  color: #ffffff;
  padding: 5px 10px;
  float: right;
}
.corrupt-pages-button{
  background-color: #005da9;
  outline: none;
  border: none;
  color: #ffffff;
  padding: 5px 10px;
  float: right;
}
.sanitize-first-modal:hover{
  background-color: #0068dd;
}
.icon{
  width: 80px;
  height: 80px;
  border-width:1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
}
.sanitizer-second-modal{
  outline: none;
  border: none;
  color: #ffffff;
  padding: 5px 10px;
}

.load-document-snackbar {
  display: inline-block;
  z-index: 999999;
  background-color: white;
  margin-bottom: 31px;
  padding: 10px;
  height: 40px;
  width: 100%;
}

.load-document-progress-bar {
  width: 300px;
}

.load-text-wrapper {
  display: flex;
  width: inherit;
  margin-top: 20px;
  white-space: nowrap;
  height: inherit;
}
.load-text {
  display: inline;
  color: white;
  margin-left: 30px;
  padding: 5px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  height: 35px;
  background-color: navy;
}