.redaction-items {
  margin-top: 0px;
  border-radius: 4px;

  >:last-child {
    padding-bottom: 8px;
  }
}

.redaction-page-group {
  padding-top: 12px;
  padding-bottom: 0px;
}

.redaction-page-group-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.redaction-page-group-number {
  display: flex;
  font-size: 13px;
  color: #868E96;
}

.expand-arrow {
  height: 16px;
  cursor: pointer;

  .Icon {
    width: 12px;
    height: 5px;
  }
}