
td {
  padding: 8px;
}

tr {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding: 8px;
  text-align: left;
  background-color: var(--primary);
  color: white;
}

table {
  border: 5px solid #ddd;
}

.filter-button {
  float: right;
}
