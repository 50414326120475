
.transformSettingsModal {
  max-height: 80vh;
  .button-container {
    margin-bottom: 150px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .change-status-btn {
    margin-right: 10px;
  }
}