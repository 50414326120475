.expand-icon {
  font-size: 24px!important;
  fill: #ffffff;
  margin: 12px;
}

.MuiAccordion-root {
  box-shadow: none!important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0!important;
}

.MuiAccordionSummary-content {
  margin: 0!important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0!important;
}

.MuiAccordionDetails-root {
  padding: 0!important;
}
