.multipanel {
  @import './styles';
  @import './overlay';

  .box {
    border: 1px solid var(--multipanelBorder);
    background-color: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    padding: 16px 9px 16px 9px;
  }

  .clear-button {
    @include button-reset;
    flex: 1 1 auto;
    color: var(--multipanelSecondaryButtonText);
    border: none;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .extra-options {
    margin-top: 5px;

    button {
      @include button-reset;
      flex: 1 1 auto;
      color: var(--multipanelSecondaryButtonText);
      border: none;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

  }

  .clearField-button {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;

    svg {
      color: #868E96;
    }

    &:hover {
      background: #E7EDF3;
    }
  }

  .filter-container {
    justify-content: space-between;
    display: inline-flex;
    width: 100%;
  }

  .filter-container li {
    margin: 3px;
  }

  .filter-label {
    width: 100%;
  }
}
