.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    color: var(--white);
    background-color: var(--primary);
}

.inner-container {
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    padding-left: 32px;
}

.icons-container {
    padding-right: 32px;
}

.company {
    display: flex;
    justify-content: center;
    align-self: center;

    p {
        font-size: 18px;
        font-weight: 100;
        margin-left: 15px;
    }
}

.logged-in-company {
    display: flex;
    align-self: center;
    // flex-grow: 1;

    p {
        font-size: 18px;
        font-weight: 100;
        margin-left: 15px;
    }
}

.profile-section {
    width: 125px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.profile {
    width: 25px;
    height: 25px;
}

.bell {
    width: 25px;
    height: 25px;
}

.linear-progress {
    position: absolute!important;
    width: calc(100% - 256px);
    top: 64px;
    left: 256px;
    height: 4px;
    background-color: #E6E7E8!important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #FCAF17!important;
}
