.left-border-1 {
    border-left: 1px solid var(--lightGrayishBlue);
}

.right-border-1 {
    border-right: 1px solid var(--lightGrayishBlue);
}

.bottom-border {
    border-bottom: 2px solid var(--lightGrayishBlue);
}

.top-border {
    border-top: 2px solid var(--lightGrayishBlue);
}

.task-details-description {
    vertical-align: top;
    overflow-y: auto;
}

.task-details-status {
    font-size: 15px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 154px;
    border-radius: 4px;
    font-weight: 600;
    color: #00000061;
}

.task-list-task-status {
    font-size: 11px;
    width: 86px;
}

.task-details-status-disabled {
    background-color: var(--lightGrayishBlue2);
}

.task-details-status-enabled {
    background-color: #9EBF30;
}

.task-details-icon {
    margin-left: 25px;
    width: 30px;
    height: 30px;
}

.task-details-small-icon {
    width: 24px;
    height: 24px;
    margin-top: 5px;
}

.task-details-second-row-column {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    flex-grow: 1;

}

.task-details-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    margin: 5px 5px 5px 40px;
}

.task-details-info-item-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    margin: 5px 5px 5px 16px;
}

.subtask-details-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    border-bottom: none;
}

.subtask-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.subtask-header-title {
    font-size: 15px;
    font-weight: 600;
    color: var(--primary);
}

.subtask-details-subtask-name {
    color: var(--primay);
    cursor: pointer;
    margin-left: 16px;
    flex-grow: 0.95;
    text-align: left;
}

.task-details-comment-section {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
    font-size: 15px;
    color: var(--primary);
}

.task-details-comment-actions-wrapper {
    width: calc(100% - 16px);
    display: flex;
    flex-direction: column;
}

.task-details-comment-actions-project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    border-top: none;
    margin: 0;
    padding: 14px;
}

.task-details-comment-actions-template {
    display: flex;
    justify-content: flex-end;
}

.task-details-comment-menu-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
    height: 45px;
    width: 100%;
    display: flex;
}

.task-details-comment-menu-component {
    cursor: pointer;
    font-weight: 600;
    color: var(--secondary);
    padding: 8px;
}


.task-details-comment-disabled-menu-component {
    cursor: default;
    font-weight: 600;
    color: gray;
    opacity: 0.4;
    padding: 8px;
}

.task-details-comment-menu-section {
    flex-grow: 0.9;
    width: calc(100% - 17px);
    overflow-y: auto;
    margin-bottom: 16px;
}

.task-details-comment-item {
    display: flex;
    background-color: var(--lightGrayishBlue);
    width: 100%;
}

.task-details-comment-menu-column {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
}

.task-details-comment-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task-details-comment-text-wrapper {
    font-size: 15px;
    letter-spacing: 0.24px;
    color: var(--darkGrayishBlue);
}

.task-details-comment-text-name {
    font-weight: 600;
    color: var(--primary);
}

.task-details-comment-text-date {
    color: var(--darkGrayishBlue);
    font-size: 13px;
    letter-spacing: 0.4px;
}

.task-details-comment-more-wrapper {
    margin: 0 16px;
}

.task-details-comment-box:focus {
    border-color: #374C72;
}

.task-details-comment-string {
    background-color: white;
    padding: 8px 16px 8px 16px;
    margin-right: 16px;
    margin-bottom: 16px;
}

.task-details-comment-mention {
    margin-right: 5px;
}

.task-details-activity-log-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 8px 16px 8px 16px;
}

.task-details-activity-log-div {
    width: 75%;
}

.task-details-activity-log-name {
    font-size: 15px;
    font-weight: 600;
}

.task-details-activity-log-content {
    color: var(--darkGrayishBlue);
    font-size: 15px;
}

.task-details-activity-log-value {
    color: var(--primary);
    font-weight: 600;
}

.task-details-activity-log-status {
    color: white;
    font-size: 11px;
    width: 86px;
    display: inline-block;
    text-align: center;
    padding: 8px 0;
}

.task-details-activity-log-date {
    color: var(--darkGrayishBlue);
    font-size: 13px;
    letter-spacing: 0.4px;
}

.time-tracking-component-wrapper {
    margin-top: 5px;
}

@mixin time-tracking-component-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.time-tracking-component-header {
    @include time-tracking-component-flex;
    margin: 0 16px;
}

.time-tracking-component-subheader {
    @include time-tracking-component-flex;
    margin: 5px 16px;
}

.time-tracking-component-text {
    font-size: 11px;
    letter-spacing: 1.5px;
}

.time-tracking-component-task-time {
    font-size: 15px;
    letter-spacing: 0.1px;
    font-weight: 600;
}

.time-tracking-component-subtasks {
    font-size: 11px;
    letter-spacing: 1.5px;
    color: var(--darkGrayishBlue);
}

.time-tracking-component-subtask-time {
    font-size: 13px;
    letter-spacing: 0.4px;
    color: var(--darkGrayishBlue);
}

.time-tracking-component-bottom {
    background-color: var(--lightGrayishBlue);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
}

.time-tracking-component-bottom-item {
    margin: 8px 16px;
}

.time-tracking-input-label {
    font-size: 11px;
    letter-spacing: 1.5px;
}

.time-tracking-input-field {
    margin-top: 5px;
}

.time-tracking-buttons {
    margin: -7px 16px;
}
