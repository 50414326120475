.arrow-back {
  color: #939598;
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 24px;
}

@mixin widget {
  display: flex;
  flex-direction: column;
  width: 432px;
  height: 490px;
  border: 1px solid #E6E7E8;
  padding-top: 24px;
  padding-left: 32px;
}

.widget {
  @include widget;
}

.widget-large {
  @include widget;
  width: 928px;
  height: 560px;
  margin-top: 32px;
}

.widget-title {
  font-size: 25px;
  color: #223250;
  margin-bottom: 32px;
}

.settings-row {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.regional-row {
  display: flex;
  margin-bottom: 8px;
}

.left-column {
  width: 78px;
  margin-right: 32px;
}

.user-color {
  font-size: 15px;
  font-weight: 600;
  color: #217DA2;
  text-decoration: underline;
  margin-left: 24px;
  cursor: pointer;
}

.user-color-icon {
  color: var(--color);
  height: 40px;
  width: 40px;
}

.edit-icon {
  margin-left: 24px;
  color: #217da2;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 24px;
  color: #217da2;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.change-password {
  width: 186px;
  height: 39px;
  font-size: 15px!important;
  margin-top: 72px!important;
}

.timezone-select {
  margin-bottom: 32px;
  width: 280px;
  height: 44px;
}

.radio-row {
  display: flex;
  align-items: center;
}

.mt-12 {
  margin-top: 12px;
}

h6 {
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.15px;
  color: #223250;
  margin-top: 0;
}

.notification-left-column {
  display: flex;
  margin-right: 24px;
}

.time-input {
  width: 76px;
  height: 44px;
  margin-right: 8px!important;
}

.am-pm-select {
  width: 87px;
  height: 44px;
}

.time-to-text {
  display: flex;
  margin: 0 16px;
  align-items: center;
}

.notification-checkbox {
  width: 24px;
  height: 24px;
  margin-right: 16px!important;
}
