.two-factor-title {
    font-size: 25px;
    text-align: center;
}

.two-factor-details {
    font-size: 15px;
    text-align: center;
}

.error-box {
    margin-top: 15px;
    margin-bottom: 10px;
}

.security-code-header {
    font-size: 15px;
    font-weight: 600;
}

.tooltips-wrapper {
    margin-top: 32px;
    margin-bottom: 0px;
}

.error-text {
    color: var(--strongRed);
}

.resend-code {
    margin-top: 10px;
    margin-bottom: 25px;
}
