.dnd-draggable-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16;
  background: white;
  border-radius: 10px;
  padding: 8px 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  font-size: 14px;
  position: relative;
  margin: 20px;
  width: 200px;
}

.dnd-draggable-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  margin-left: 12px;
}

.dnd-draggable-file-number-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #217da2;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
}
